import { makeStyles } from '@mui/styles';



const useDefaultStyles = makeStyles({
  secondaryColor: {
    background: "#F3F1F5",
  },
  appbar: {
    '& .RaAppBar-title': {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  typography: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: "100px",
  },
  toolbar: {
    '& .RaToolbar-desktopToolbar':{
      background: "#9c27b0"
    }
  }
});

export default useDefaultStyles;