import { Fragment } from 'react';
import { SelectInput, TextInput, required, useGetList, useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import { Subjects } from "./Subjects";

const Cohorts = (props) => {
  const videoWidgetId = props.videoWidgetId
  const dataProvider = useDataProvider();

  const { data: categoriesResponse, isLoading: isCategoriesLoading, error: categoriesError }= useGetList('video/categories')
  const { data: cohortResponse, isLoading: isCohortLoading, error: cohortError } = useQuery(
    ['widget_cohort', videoWidgetId],
    () => dataProvider.fetchCohort('video/widgets', { widgetID: videoWidgetId })
  );

  if (isCategoriesLoading || categoriesError || isCohortLoading || cohortError) return (<Fragment> </Fragment>)
  return  (
    <Fragment>
      <TextInput source='cohort_id' defaultValue={cohortResponse.data.name} validate={[required()]} disabled fullWidth />
      <SelectInput source="category_id" choices={categoriesResponse} validate={[required()]} parse={value => value.toString()} fullWidth resettable />
      <Subjects cohortId={cohortResponse.data.id}/>
    </Fragment>
  )
}

export { Cohorts };