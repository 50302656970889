import * as React from 'react';
import {
  Edit, SimpleForm, TextInput, DateTimeInput, SelectInput, NumberInput, required, useUpdate, useRedirect,
  FormDataConsumer
} from 'react-admin';
import { WidgetTypeChoices } from 'widget_service/constants/choices'
import { availabilityStartingValidations, availabilityUntilValidations } from './validations'
import { NAME_FIELD_DESCRIPTION } from 'widget_service/constants/field_descriptions'
import { CanDisplayField } from 'widget_service/video/utils';
import NoDeleteToolbar from 'common_components/NoDeleteToolbar'

export const VideoWidgetEdit = () => {
  const [update] = useUpdate();
  const redirect = useRedirect();
  const save = React.useCallback(
    async values => {
      try {
        await update('video/widgets', { id: values['id'], data: values }, { returnPromise: true });
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
      redirect('show', 'video/widgets', values['id']);
    },
    [update, redirect]
  );

  return (
    <Edit>
      <SimpleForm toolbar={<NoDeleteToolbar />} onSubmit={save}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let widgetType = formData.type;

            return (
              <>
                <TextInput source='name' validate={[required()]} helperText={ NAME_FIELD_DESCRIPTION } fullWidth />
                <SelectInput source='type' choices={WidgetTypeChoices} validate={[required()]} fullWidth disabled />
                {CanDisplayField(widgetType, 'widget_availability_starting') && <DateTimeInput
                  source='availability_starting'
                  validate={availabilityStartingValidations}
                  fullWidth
                />}
                {CanDisplayField(widgetType, 'widget_availability_until') && <DateTimeInput
                  source='availability_until'
                  validate={availabilityUntilValidations}
                  fullWidth
                />}
                {CanDisplayField(widgetType, 'widget_sort_sequence') && <NumberInput
                  source='sort_sequence'
                  validate={[required()]}
                  fullWidth
                />}
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export default VideoWidgetEdit;
