import * as React from 'react';
import {
  SimpleForm, TextInput, DateTimeInput, AutocompleteInput, required, ArrayInput, useChoicesContext,
  Create, SimpleFormIterator, ReferenceInput, useCreate, useRedirect, SelectInput, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import {
  availabilityStartingValidations, availabilityUntilValidations
} from './validations'
import { CtaTypes } from 'widget_service/constants/choices';
import { validURL } from 'common_validations/regex';
import { NAME_FIELD_DESCRIPTION } from 'widget_service/constants/field_descriptions';
import { CanDisplayField } from 'widget_service/video/utils';

const Element = (props) => {
  const { selectedChoices } = useChoicesContext();
  let widgetType;
  if (selectedChoices.length !== 0 && selectedChoices[0] !== undefined) {
    widgetType = selectedChoices[0].type
  }
  return (
    <>
      <AutocompleteInput optionText="name" validate={[required()]} fullWidth />
      {CanDisplayField(widgetType, 'element_campaign_id') && <TextInput source='campaign_id' fullWidth />}
      {CanDisplayField(widgetType, 'element_cta') && <TextInput source='cta_title' fullWidth />}
      {CanDisplayField(widgetType, 'element_cta') && <SelectInput source='cta_type' choices={CtaTypes} fullWidth />}
      {CanDisplayField(widgetType, 'element_cta') && <TextInput source='cta_link' validate={[validURL]} fullWidth />}
      {CanDisplayField(widgetType, 'element_labels') && <ArrayInput source='label_list' sx={{paddingTop: '20px'}}>
        <SimpleFormIterator>
          <TextInput validate={[required()]} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>}
      {CanDisplayField(widgetType, 'element_availability_timings') && <DateTimeInput
        source='availability_starting'
        validate={availabilityStartingValidations}
        fullWidth
      />}
      {CanDisplayField(widgetType, 'element_availability_timings') && <DateTimeInput
        source='availability_until'
        validate={availabilityUntilValidations}
        fullWidth
      />}
    </>
  )
}

export const VideoElementCreate = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const save = React.useCallback(
    async values => {
      values['video_widget_id'] = values['video_widget_id'].toString();
      var response;
      try {
        response = await create('video/elements', { data: values }, { returnPromise: true });
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
      redirect('show', 'video/elements', response['id']);
    },
    [create, redirect]
  );

  return (
    <Create {...props}>
      <SimpleForm onSubmit={save}>
        <TextInput source='name' validate={[required()]} helperText={ NAME_FIELD_DESCRIPTION } fullWidth />
        <ReferenceInput label='Video Widget' source='video_widget_id' reference='video/widgets'>
          <Element />
        </ReferenceInput>
        <TextInput source='title' fullWidth />
        <ReferenceArrayInput source='user_segment_ids' reference='video/user-segments' >
          <AutocompleteArrayInput fullWidth />
        </ReferenceArrayInput>
        <TextInput source='video_url' validate={[validURL]} fullWidth />
        <TextInput source='hls_video_url' validate={[validURL]} fullWidth />
        <TextInput source='dash_video_url' validate={[validURL]} fullWidth />
        <TextInput source='drm_hls_video_url' validate={[validURL]} fullWidth />
        <TextInput source='drm_dash_video_url' validate={[validURL]} fullWidth />
        <TextInput source='thumbnail_url' validate={[validURL, required()]} fullWidth />
        <TextInput source='starts_at' defaultValue={'0'} validate={[required()]} fullWidth />
        <TextInput source='ends_at' defaultValue={'10'} validate={[required()]} fullWidth />
        <TextInput source='duration' defaultValue={'10'} validate={[required()]} fullWidth />
        <TextInput source='view_count' validate={[required()]} fullWidth />
        <TextInput source='sort_sequence'  validate={[required()]} fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default VideoElementCreate;
