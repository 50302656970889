import React from 'react';
import { Navigate } from 'react-router-dom';

// Route Views
import LoginPage from './containers/login/LoginPage'
import AuthenticationPage from './containers/login/AuthenticationPage'

export const routePaths = [
  {
    path: '/',
    exact: true,
    component: () => <Navigate to={'/login'} />,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/authenticate',
    component: AuthenticationPage,
  },
];
