import { Navigate, useLocation } from 'react-router-dom';

import TLLMSDataProvider from 'common_components/data_providers';

const saveRoles = (token) => {
  TLLMSDataProvider.getRoles(token).then(({ json }) => {
    const roles = json.roles;
    localStorage.setItem('user_roles',  roles);
  })
}

const AuthenticationPage = () =>{
  const location = useLocation();
  const params = new URLSearchParams(location.hash);
  const access_token = params.get('#access_token')
  localStorage.setItem('token',  access_token)
  saveRoles(access_token)

  return <Navigate to='/sdui/admin'  />
}

export default AuthenticationPage
