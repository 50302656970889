import React from 'react';
import { Navigate } from "react-router-dom";

const IsAuthenticated = () => {
  const accessKey = localStorage.getItem('token');
  return accessKey !== null
}

function NoAuth({ children }: { children: JSX.Element }) {
  if (IsAuthenticated()) {
    return <Navigate to="/sdui" />;
  }

  return children;
}

export default NoAuth;
