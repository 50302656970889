import * as React from "react";
import {
  List, Datagrid, TextField, SelectField, EditButton, ExportButton, CreateButton, TopToolbar, TextInput
} from 'react-admin';
import IsAvailableField from 'common_components/IsAvailableField';
import { WidgetTypeChoices, StatusChoices } from 'widget_service/constants/choices';

import { hasWriteAccess } from 'common_validations/roles';

const VideoWidgetFilters = [
  <TextInput source="query" label="Search Name" alwaysOn />
]

const VideoWidgetActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <CreateButton />}
    <ExportButton />
  </TopToolbar>
)

export const VideoWidgetList = () => (
    <List actions={<VideoWidgetActions />} filters={VideoWidgetFilters}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <SelectField source="type" choices={WidgetTypeChoices} />
        <TextField source="name" />
        <TextField source="sort_sequence" />
        <IsAvailableField label="Is Available" />
        <SelectField source="status" choices={StatusChoices.choices} />
        {hasWriteAccess('video_widgets') && <EditButton />}
      </Datagrid>
    </List>
);

export default VideoWidgetList;
