import * as React from "react";
import { useRecordContext } from 'react-admin';
import { BooleanField } from 'react-admin';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const IsAvailableField = ({ source }) => {
    const record = useRecordContext();
    let availabilityStarting = new Date(record['availability_starting']).getTime(),
        availabilityUntil = new Date(record['availability_until']).getTime(),
        currentDate = new Date().getTime();
    if (
      (isNaN(availabilityStarting) || availabilityStarting < currentDate) &&
      (isNaN(availabilityUntil) || availabilityUntil > currentDate)
    ) {
      record["is_available"] = true;
    } else {
      record["is_available"] = false;
    }
    return record ? (
      <BooleanField source="is_available" TrueIcon={VisibilityOnIcon} FalseIcon={VisibilityOffIcon} />
    ) : null;
}

export default IsAvailableField;
