export const PageTypeChoices = [{ id: 'home', name: 'Home Page' }, { id: 'self_study', name: 'Self Study Page' }]

export const StatusChoices = { choices: [{ id: 'draft', name: 'Draft' }, { id: 'published', name: 'Published' }]}

export const GradeChoices = [
  {id: 'Standard I', name: 'Standard I'},
  {id: 'Standard II', name: 'Standard II'},
  {id: 'Standard III', name: 'Standard III'},
  {id: 'Standard IV', name: 'Standard IV'},
  {id: 'Standard V', name: 'Standard V'},
  {id: 'Standard VI', name: 'Standard VI'},
  {id: 'Standard VII', name: 'Standard VII'},
  {id: 'Standard VIII', name: 'Standard VIII'},
  {id: 'Standard IX', name: 'Standard IX'},
  {id: 'Standard X', name: 'Standard X'},
  {id: 'Standard XI', name: 'Standard XI'},
  {id: 'Standard XII', name: 'Standard XII'},
  {id: 'Standard XIII', name: 'Standard XIII'},
  {id: 'Other', name: 'Other'},
  {id: 'Nursery', name: 'Nursery'},
  {id: 'Primary', name: 'Primary'},
  {id: 'JKG', name: 'JKG'},
  {id: 'SKG', name: 'SKG'},
  {id: 'Pre Primary', name: 'Pre Primary'},
  {id: 'Pre School', name: 'Pre School'},
  {id: 'UKG', name: 'UKG'},
  {id: 'LKG', name: 'LKG'}
]

export const LIVE = 'live', LEARNING = 'learning', SHORTS = 'shorts'

export const WidgetTypeChoices = [
  {id: LIVE, name: 'Live Video Widget'},
  {id: LEARNING, name: 'Learning Video Widget'},
  {id: SHORTS, name: 'Video Shorts'}
]

export const CtaTypes = [{id: 'webview', name: 'Web View'}, {id: 'deeplink', name: 'Deep Link'}]

export const SubjectMappingType = 'subject', ChapterMappingType = 'chapter', SubTopicMappingType = 'sub_topic'

export const ElementMappingTypeChoices = [
  {id: SubjectMappingType, name: 'Subject'},
  {id: ChapterMappingType, name: 'Chapter'},
  // {id: SubTopicMappingType, name: 'Sub-Topic'},
  {id: 'experimental', name: 'Experimental'},
  {id: 'fun_fact', name: 'Fun Fact'}
]

export const ElementPropertiesMetadataPresenter = [
  { id: 'No human', name: 'No human' },
  { id: 'Aswin', name: 'Aswin' },
  { id: 'Vinay', name: 'Vinay' },
  { id: 'Tarana', name: 'Tarana' },
  { id: 'Ruchi', name: 'Ruchi' },
  { id: 'Aishwarya', name: 'Aishwarya' },
  { id: 'Saurabh', name: 'Saurabh' },
  { id: 'Ankita', name: 'Ankita' },
  { id: 'Arshdeep', name: 'Arshdeep' },
  { id: 'Chetna', name: 'Chetna' },
  { id: 'Guest', name: 'Guest' },
  { id: 'Student', name: 'Student' }
]

export const ElementPropertiesMetadataThumbnail = [
  { id: 'Clickbait', name: 'Clickbait' },
  { id: 'Regular', name: 'Regular' }
]

export const ElementPropertiesMetadataAudio = [
  { id: 'Music', name: 'Music' },
  { id: 'Voice Over', name: 'Voice Over' },
  { id: 'Presenter Led', name: 'Presenter Led' },
  { id: 'No Audio', name: 'No Audio' }
]

export const ElementPropertiesMetadataVideoType = [
  { id: 'Youtube', name: 'Youtube' },
  { id: 'Freshly created', name: 'Freshly created' },
  { id: 'Revamp reuse', name: 'Revamp reuse' }
]