import { Fragment, useState } from 'react';
import { TextInput, useGetList, useEditContext, AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Subtopics } from "./Subtopics";
import { Concepts } from "./Concepts";

const Chapters = (props) => {
  const { record } = useEditContext();
  const [chapterId, setChapterId] = useState(record?.chapter_id);

  const formContext = useFormContext();
  const handleChapterIdChange = (value) => {
    setChapterId(value);
    formContext.setValue('chapter_name', "");
    formContext.setValue('subtopic_id', "");
    formContext.setValue('subtopic_name', "");
    formContext.setValue('concept_id', "");
    formContext.setValue('concept_name', "");
  };

  const { data: chaptersResponse , isLoading: areChaptersLoading, error: chaptersError } = useGetList('categories', {
    filter: {
      type: 'chapter',
      ancestry_id: props.subjectId || ""
    },
    pagination: { page: 1, perPage: 200 }
  });

  const ChapterNameInput = (props) => {
    const { data, isLoading, error } = useGetList('categories', {
      filter: {
        id: props.chapterId || ""
      }
    });

    if (isLoading || error || data.length === 0) return (<Fragment> </Fragment>)

    if (formContext.getValues('chapter_id') !== "") {
      formContext.setValue('chapter_name', data[0]?.name);
    }
    return (
        <TextInput source='chapter_name' disabled fullWidth />
    );
  };

  if (areChaptersLoading || chaptersError) return (<Fragment> </Fragment>)
  return  (
    <Fragment>
      <AutocompleteInput source='chapter_id' onChange={handleChapterIdChange} choices={chaptersResponse} optionText="id" parse={value => value.toString()} resettable fullWidth />
      <ChapterNameInput chapterId={chapterId} />
      <Subtopics chapterId={chapterId}/>
      <Concepts chapterId={chapterId}/>
    </Fragment>
  )
}

export { Chapters };