import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils, combineDataProviders } from 'react-admin';

import TLLMSDataProvider from 'common_components/data_providers';

const updateDataProvider = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
  return fetchUtils.fetchJson(url, options);
}

const sduiDataProvider = jsonServerProvider(process.env.REACT_APP_SDUI_SERVICE_BASE_URL, updateDataProvider);

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'cohorts':
      return TLLMSDataProvider;
    default:
      return sduiDataProvider;
  }
});

export default dataProvider
