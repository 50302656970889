import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { routePaths } from './routes';
import RequireAuth from 'auth/RequireAuth';
import NoAuth from 'auth/NoAuth';
import UIComponentAdmin from 'sdui_service/app';
import WidgetAdmin from 'widget_service/app';

import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {routePaths.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <NoAuth>
                    <route.component />
                  </NoAuth>
                }
              />
            );
          })}
          <Route
            key="sduiService"
            path="/sdui/*"
            element={
              <RequireAuth>
                <UIComponentAdmin />
              </RequireAuth>
            }
          />
          <Route
            key="widgetService"
            path="/widget/*"
            element={
              <RequireAuth>
                <WidgetAdmin />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
