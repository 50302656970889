import * as React from 'react';
import {
  TextField, SelectField, Show, TabbedShowLayout, Tab, DateField, ReferenceField, ImageField, SingleFieldList, ChipField, ReferenceArrayField
} from 'react-admin';
import { CtaTypes } from 'widget_service/constants/choices';
import ListChipField from 'common_components/ListChipField'
import useDefaultStyles from "../../../common_components/Themes"

import ElementMappingsTab from './mappings_tab';
import ElementPropertiesTab from './properties_tab';

export const VideoElementShow = () => {
  const classes = useDefaultStyles();
  return (
    <Show>
      <TabbedShowLayout className={classes.secondaryColor}>
        <Tab label='Element Details'>
            <ReferenceField label='Video Widget' source='video_widget_id' reference='video/widgets' link='show'>
              <TextField source='name' />
            </ReferenceField>
            <TextField source='name' />
            <TextField source='title' />
            <ReferenceArrayField source='user_segment_ids' reference='video/user-segments'>
              <SingleFieldList>
                  <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <TextField source='video_url' />
            <TextField source='hls_video_url' />
            <TextField source='dash_video_url' />
            <TextField source='drm_hls_video_url' />
            <TextField source='drm_dash_video_url' />
            <TextField source='thumbnail_url' />
            <ImageField source='thumbnail_url'  label=''/>
            <TextField source='campaign_id' />
            <TextField source='cta_title' />
            <SelectField choices={CtaTypes} source='cta_type' />
            <TextField source='cta_link' />
            <ListChipField source='label_list' />
            <DateField source='availability_starting' />
            <DateField source='availability_until' />
            <TextField source='starts_at' />
            <TextField source='ends_at' />
            <TextField source='duration' />
            <TextField source='sort_sequence' />
            <TextField source='view_count' />
        </Tab>
        <Tab label='Element Mappings'>
          <ElementMappingsTab />
        </Tab>
        <Tab label='Element Properties'>
          <ElementPropertiesTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default VideoElementShow;
