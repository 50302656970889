import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/system';

const JSONField = ({ source, jsonSource }) => {
  const record = useRecordContext();
  var src = JSON.stringify(record[source], null, 2);
  record[jsonSource] = src;

  return (
    <Box sx={{ height: 400, overflow: 'auto' }}>
      <pre>{src}</pre>
    </Box>
  );
};

export default JSONField;
