import * as React from 'react';
import {
  TextField, SelectField, Show, TabbedShowLayout, Tab, NumberField, ReferenceField,
} from 'react-admin';

import { PageTypeChoices } from 'widget_service/constants/choices';
import ElementMappingsTab from './element_mappings_tab'
import useDefaultStyles from "../../../common_components/Themes"

export const VideoWidgetMappingShow = () => {
  const classes = useDefaultStyles();
  return (
    <Show actions={false}>
      <TabbedShowLayout className={classes.secondaryColor}>
        <Tab label='Widget Mapping Details'>
          <NumberField source='id' />
          <ReferenceField label='Video Widget' source='video_widget_id' reference='video/widgets' link='show'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField label='Cohort' reference='cohorts' source='tnl_cohort_id' link={false}>
            <TextField source='name' />
          </ReferenceField>
          <SelectField source='page_type' choices={PageTypeChoices} />
          <TextField source='user_segment' />
        </Tab>
        <Tab label='Element Mappings'>
          <ElementMappingsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default VideoWidgetMappingShow;

