import * as React from "react";
import {
  List, Datagrid, TextField, EditButton, TextInput, ExportButton, CreateButton, TopToolbar, ReferenceField
} from 'react-admin';

import { hasWriteAccess } from 'common_validations/roles';

const VideoElementPropertiesFilters = [
  <TextInput source="query" label="Search Name" alwaysOn />
]

const VideoElementPropertiesActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <CreateButton />}
    <ExportButton />
  </TopToolbar>
)

export const VideoElementPropertiesList = () => (
  <List actions={<VideoElementPropertiesActions />} filters={VideoElementPropertiesFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="category_id" reference="video/categories" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="subject_id" label="Subject ID" />
      <TextField source="chapter_id" label="Chapter ID" />
      <TextField source="subtopic_id" label="Subtopic ID" />
      <TextField source="concept_id" label="Concept ID" />
      <TextField source="tags" />
      {hasWriteAccess('video_widgets') && <EditButton />}
    </Datagrid>
  </List>
);

export default VideoElementPropertiesList;