import * as React from "react";
import { useRecordContext } from 'react-admin';
import { ArrayField, SingleFieldList, ChipField } from 'react-admin';

const ListChipField = ({ source, labelMap, ...props }) => {
  const record = useRecordContext();
  const fieldData = record[source] || [];

  let chips = fieldData.map((item) => {
    if (props.choices !== undefined) {
      return props.choices.find((choice) => {return choice.id === item})
    }
    return {'name': item}
  });
  labelMap = labelMap || 'labelMap'
  record[labelMap] = chips;

  return record[labelMap].length !== 0 ? (
    <ArrayField source={labelMap}>
      <SingleFieldList>
        <ChipField source="name" emptyText='None' />
      </SingleFieldList>
    </ArrayField>
  ) : null
}

export default ListChipField;

