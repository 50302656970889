import * as React from "react";
import { useRecordContext } from 'react-admin';
import {
  List, Datagrid, TextField, TopToolbar, ExportButton, Button,
  useRedirect
} from 'react-admin';

import Empty from 'common_components/Empty';
import { hasWriteAccess } from 'common_validations/roles';


const ElementMappingsTab = ({ source }) => {
  const record = useRecordContext();
  return (
      <List
        resource='video/element-mappings'
        filter={{ video_element_id: record.id }}
        actions={<VideoElementMappingActions />}
        empty={
          <Empty 
            canWrite={hasWriteAccess('video_widgets')}
            customCreateButton={<MappingCreateButton variant='container' />}
          />
        }
      >
        <Datagrid rowClick='show' bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="display_name" />
          <TextField source="mapping_type" />
          <TextField source="mapping_id" />
        </Datagrid>
      </List>
  )
}

const MappingCreateButton = () => {
  const context = useRecordContext();
  const redirect = useRedirect();
  const handleClick = () => {
    redirect('create', 'video/element-mappings', 0, {}, {"record": {"video_element_id": context.id}});
  }
 return (
   <Button label='Create' color='primary' onClick={handleClick} />
 )
}

const VideoElementMappingActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <MappingCreateButton />}
    <ExportButton />
  </TopToolbar>
)

export default ElementMappingsTab;