import {
  LIVE, LEARNING, SHORTS
} from 'widget_service/constants/choices'

export const FieldMap = {
  [LIVE]: [
    'widget_availability_starting',
    'widget_availability_until',
    'widget_sort_sequence',
    'page_filter',
    'user_segment_filter',
    'element_campaign_id',
    'element_cta',
    'element_labels',
    'element_availability_timings',
  ],
  [LEARNING]: [
    'widget_availability_starting',
    'widget_availability_until',
    'widget_sort_sequence',
    'page_filter',
    'user_segment_filter',
    'element_campaign_id',
    'element_cta',
    'element_labels',
    'element_availability_timings',
  ],
  [SHORTS]: []
}
