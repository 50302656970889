import * as React from "react";
import { useRecordContext } from 'react-admin';
import { List, Datagrid, TextField, TopToolbar, CreateButton, ExportButton } from 'react-admin';

import { hasWriteAccess } from 'common_validations/roles';

const ElementsTabActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <CreateButton />}
    <ExportButton />
  </TopToolbar>
)


const ElementsTab = ({ source }) => {
  const record = useRecordContext();
  return (
      <List
        resource='video/element-mappings'
        filter={{ video_widget_mapping_id: record.id }}
        actions={<ElementsTabActions />}
      >
        <Datagrid rowClick='show' bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="display_name" />
          <TextField source="mapping_type" />
          <TextField source="mapping_id" />
        </Datagrid>
      </List>
  )
}

export default ElementsTab;
