import { Fragment, useState } from 'react';
import { useGetList, TextInput, useEditContext, AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const Concepts = (props) => {
  const { record } = useEditContext();
  const [conceptId, setConceptId] = useState(record?.concept_id);

  const formContext = useFormContext();
  const handleConceptIdChange = (value) => {
    setConceptId(value);
    formContext.setValue('concept_name', "");
  };

  const { data: conceptsResponse , isLoading: areConceptsLoading, error: conceptsError } = useGetList('concepts', {
    filter: {
      chapter_id: props.chapterId || ""
    },
    pagination: { page: 1, perPage: 200 }
  });

  const ConceptNameInput = (props) => {
    const { data, isLoading, error } = useGetList('concepts', {
      filter: {
        id: props.conceptId || ""
      }
    });

    if (isLoading || error || data.length === 0) return (<Fragment> </Fragment>)

    if (formContext.getValues('concept_id') !== "") {
      formContext.setValue('concept_name', data[0]?.name);
    }
    return (
        <TextInput source='concept_name' disabled fullWidth />
    );
  };

  if (areConceptsLoading || conceptsError) return (<Fragment> </Fragment>)
  return  (
    <Fragment>
      <AutocompleteInput source='concept_id' onChange={handleConceptIdChange} choices={conceptsResponse} optionText="id" parse={value => value.toString()} resettable fullWidth />
      <ConceptNameInput conceptId={conceptId} />
    </Fragment>
  )
}

export { Concepts };