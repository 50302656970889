import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
} from 'react-admin';
import {
  AppFlavourChoices,
  DeviceOrientationChoices,
  DeviceOSChoices,
  ThemeNameChoices,
  GradeChoices,
  SubscriptionTypeChoices,
  SubjectChoices,
  ComponentTypeChoices,
} from './constants';
import { Alert } from "@mui/material";

import { hasWriteAccess } from 'common_validations/roles';

import ListChipField from 'common_components/ListChipField';

const postFilters = [
  <AutocompleteInput source="type" choices={ComponentTypeChoices} />,
  <AutocompleteInput
    source="app_flavour_id"
    choices={AppFlavourChoices}
  />,
  <NumberInput source="schema_version" />,
  <ReferenceInput label='Cohorts' reference='cohorts' source='cohort_id'>
    <AutocompleteInput optionText='name' fullWidth />
  </ReferenceInput>,
  <AutocompleteInput
    source="device_orientation"
    choices={DeviceOrientationChoices}
  />,
  <AutocompleteInput source="device_os" choices={DeviceOSChoices} />,
  <AutocompleteInput source="theme_name" choices={ThemeNameChoices} />,
  <AutocompleteInput source="grade" choices={GradeChoices} />,
  <AutocompleteInput
    source="subscription_type"
    choices={SubscriptionTypeChoices}
  />,
  <AutocompleteInput source="subject" choices={SubjectChoices} />,
];

export const UIComponentList = () => (
  <>
    <Alert sx={{ marginTop: 4 }} severity="warning">
      Consider migrating to new <a href="https://neon-staging.tllms.com/">SDUI</a>.
    </Alert>
    <List filters={postFilters}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="type" />
        <TextField source="schema_version" />
        <ListChipField source="device_orientation" />
        <DateField source="created_at" />
        {hasWriteAccess('sdui') && <EditButton />}
      </Datagrid>
    </List>
  </>
);

export default UIComponentList;
