import * as React from 'react';
import { AppBar, Toolbar } from 'react-admin';
import Typography from '@mui/material/Typography';
import LogoutButton from 'common_components/Logout';
import logo from '../Images/logo.png';
import useDefaultStyles from "../common_components/Themes"

const CustomAppBar = (props) => {
  const classes = useDefaultStyles();
  return (
    <AppBar
      {...props}
      className={classes.appbar}
    >
      <Toolbar className={classes.toolbar}>
        <img src={logo} alt="logo" className={classes.logo} />
      </Toolbar>
      <Typography
        variant="h6"
        id="react-admin-title"
        noWrap
        className={classes.typography}
      />
      <LogoutButton />
    </AppBar>
  );
};

export default CustomAppBar;
