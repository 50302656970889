import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useEditContext,
  useGetOne,
  SelectInput
} from 'react-admin';
import {Cohorts} from './components/Cohorts'
import {  ElementPropertiesMetadataPresenter, ElementPropertiesMetadataThumbnail, ElementPropertiesMetadataAudio,ElementPropertiesMetadataVideoType} from "../../constants/choices";

const VideoElementPropertiesEdit = (props) => {
  return (
    <Edit title='Edit Element Properties'>
      <SimpleForm>
        <TextInput source='id' validate={[required()]} disabled fullWidth />
        <VideoElementPropertiesEditDetails />
        <TextInput source='tags' resettable fullWidth />
        <SelectInput source='metadata_presenter' choices={ElementPropertiesMetadataPresenter} resettable fullWidth />
        <SelectInput source='metadata_video_type' choices={ElementPropertiesMetadataVideoType} resettable fullWidth />
        <SelectInput source='metadata_audio' choices={ElementPropertiesMetadataAudio} resettable fullWidth />
        <SelectInput source='metadata_thumbnail' choices={ElementPropertiesMetadataThumbnail} resettable fullWidth />
      </SimpleForm>
    </Edit>
  )
}
const VideoElementPropertiesEditDetails = () => {
  const { record, isLoading } = useEditContext();
  if (isLoading) return null;
  return (
    <CohortFromVideoElement videoElementId={record.video_element_id}/>
  );
};

const CohortFromVideoElement = (props) => {
  const { data: videoElementResponse, isLoading: isVideoElementLoading, error: videoElementError } = useGetOne('video/elements', { id:  props.videoElementId })
  if(isVideoElementLoading || videoElementError) return null;

  return (
    <Cohorts videoWidgetId={videoElementResponse.video_widget_id} validate={[required()]} resettable />
  );
};

export default VideoElementPropertiesEdit;