import * as React from 'react';
import { Admin, Resource, Layout } from 'react-admin';

import dataProvider from 'widget_service/data_provider';
import MenuBar from 'common_components/menu_bar';
import CustomAppBar from 'common_components/app_bar';
import CustomTheme from 'Themes/CustomTheme'

import { hasReadAccess, hasWriteAccess } from 'common_validations/roles';

import VideoWidgetList from './widget/list';
import VideoWidgetEdit from './widget/edit';
import VideoWidgetShow from './widget/show';
import VideoWidgetCreate from './widget/create';

import VideoElementList from './element/list';
import VideoElementEdit from './element/edit';
import VideoElementShow from './element/show';
import VideoElementCreate from './element/create';

import VideoWidgetMappingCreate from './widget_mapping/create';
import VideoWidgetMappingList from './widget_mapping/list';
import VideoWidgetMappingShow from './widget_mapping/show';
import VideoWidgetMappingEdit from './widget_mapping/edit';

import VideoElementMappingCreate from './element_mapping/create';
import VideoElementMappingList from './element_mapping/list';
import VideoElementMappingShow from './element_mapping/show';
import VideoElementMappingEdit from './element_mapping/edit';

import VideoElementPropertiesCreate from './element_properties/create';
import VideoElementPropertiesList from './element_properties/list';
import VideoElementPropertiesShow from './element_properties/show';
import VideoElementPropertiesEdit from './element_properties/edit';

const CustomLayout = (props) => <Layout
    {...props}
    menu={MenuBar}
    appBar={CustomAppBar}
/>;

const VideoAdmin = () => (
    <Admin theme={CustomTheme} layout={CustomLayout} basename='/widget' dataProvider={dataProvider}>
      <Resource
        name='video/widgets'
        list={hasReadAccess('video_widgets') && VideoWidgetList}
        edit={hasWriteAccess('video_widgets') && VideoWidgetEdit}
        show={hasReadAccess('video_widgets') && VideoWidgetShow}
        create={hasWriteAccess('video_widgets') && VideoWidgetCreate}
        options={{ label: 'Video Widget' }}
      />
      <Resource
        name='video/elements'
        list={hasReadAccess('video_widgets') && VideoElementList}
        edit={hasWriteAccess('video_widgets') && VideoElementEdit}
        show={hasReadAccess('video_widgets') && VideoElementShow}
        create={hasWriteAccess('video_widgets') && VideoElementCreate}
        options={{ label: 'Video Elements' }}
      />
      <Resource
        name='video/widget-mappings'
        list={hasReadAccess('video_widgets') && VideoWidgetMappingList}
        show={hasReadAccess('video_widgets') && VideoWidgetMappingShow}
        create={hasWriteAccess('video_widgets') && VideoWidgetMappingCreate}
        edit={hasWriteAccess('video_widgets') && VideoWidgetMappingEdit}
        options={{ label: 'Mappings' }}
      />
      <Resource
        name='video/element-mappings'
        list={hasReadAccess('video_widgets') && VideoElementMappingList}
        show={hasReadAccess('video_widgets') && VideoElementMappingShow}
        create={hasWriteAccess('video_widgets') && VideoElementMappingCreate}
        edit={hasWriteAccess('video_widgets') && VideoElementMappingEdit}
        options={{ label: 'Element Mappings' }}
      />
      <Resource
        name='video/element-properties'
        list={hasReadAccess('video_widgets') && VideoElementPropertiesList}
        show={hasReadAccess('video_widgets') && VideoElementPropertiesShow}
        create={hasWriteAccess('video_widgets') && VideoElementPropertiesCreate}
        edit={hasWriteAccess('video_widgets') && VideoElementPropertiesEdit}
        options={{ label: 'Element Properties' }}
      />
    </Admin>
);

export default VideoAdmin;

