import {defaultTheme} from 'react-admin';
import purple from '@mui/material/colors/purple';

const CustomTheme = {
  ...defaultTheme,
  palette: {
    primary: purple,
  },
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: "#EFEFEF",
          "& .RaDatagrid-headerCell": {
            backgroundColor: purple["300"],
            fontWeight: 800,
          },
        }
      }
    },
    RaImageField:{
      styleOverrides: {
        root: {
          "& .RaImageField-image": {
            width : 'unset',
            height: 300,
            borderRadius: '6px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.4)'
          },
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    }
  }
};

export default CustomTheme;