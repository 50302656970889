import * as React from 'react';
import {
  Create, SimpleForm, AutocompleteInput, TextInput, ReferenceInput, SelectInput, required, useCreate, useChoicesContext,
  useRedirect, useRefresh, FormDataConsumer
} from 'react-admin';

import TLLMSDataProvider from 'common_components/data_providers';

import {
  ElementMappingTypeChoices, SubjectMappingType, ChapterMappingType, SubTopicMappingType
} from 'widget_service/constants/choices'

let cohortChoices = {},
    isLoading = true;

const ElementSubjectMapping = (props) => {
  const { selectedChoices } = useChoicesContext();
  let subjectName;
  if (selectedChoices.length !== 0 && selectedChoices[0] !== undefined) {
    subjectName = selectedChoices[0].name
  }
  return (
    <>
      <AutocompleteInput optionText='name' fullWidth />
      <TextInput source='display_name' defaultValue={subjectName} validate={[required('This field is required')]} fullWidth />
    </>
  )
}

const ElementMapping = (props) => {
  const { selectedChoices, allChoices } = useChoicesContext();
  const refresh = useRefresh();
  let cohortID;
  if (selectedChoices.length !== 0 && selectedChoices[0] !== undefined) {
    cohortID = selectedChoices[0].tnl_cohort_id
  }
  if (allChoices.length !== 0) {
    updateChoices(allChoices).then(() => {
      refresh();
    });
  }
  const mappingTypeOptionRenderer = choice => {
    return cohortChoices[choice.tnl_cohort_id];
  }

  return (
    <>
      <SelectInput optionText={mappingTypeOptionRenderer} validate={[required()]} fullWidth disabled={isLoading} />
      <FormDataConsumer>
      {({formData, ...rest}) => {
        let mappingType = formData?.mapping_type;

        return (
          <>
            <AutocompleteInput source='mapping_type' validate={[required()]} choices={ElementMappingTypeChoices} fullWidth />
            {
              hasAutofilledID(mappingType) &&
              <ReferenceInput
                reference='categories'
                source='mapping_id'
                filter={ createMappingFilter(mappingType, cohortID) }
              >
                <ElementSubjectMapping />
              </ReferenceInput>
            }
            {hasAutofilledID(mappingType) || <TextInput
              source='display_name' fullWidth
              validate={[required('This field is required')]}
            />}
            <ReferenceInput
              label='Video Element'
              source='video_element_id'
              reference='video/elements'
              filter={{ video_widget_mapping_id: formData?.video_widget_mapping_id }}
            >
              <AutocompleteInput optionText='name' validate={[required()]} fullWidth />
            </ReferenceInput>
          </>
        )
      }}
      </FormDataConsumer>
    </>
  )
}

const VideoElementMappingCreate = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const save = React.useCallback(
    async values => {
      var response;
      try {
        response = await create('video/element-mappings', { data: values }, { returnPromise: true });
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
      redirect('show', 'video/element-mappings', response['id']);
    },
    [create, redirect]
  );

  return (
    <Create {...props}>
      <SimpleForm onSubmit={save}>
        <FormDataConsumer>
        {({formData, ...rest}) => {

          return (
            <ReferenceInput
              label='Video Widget Mapping'
              source='video_widget_mapping_id'
              reference='video/widget-mappings'
              filter={{ video_element_id: formData?.video_element_id }}
            >
              <ElementMapping />
            </ReferenceInput>
          )
        }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

const hasAutofilledID = (mappingType, form, scope) => {
  return [SubjectMappingType, ChapterMappingType, SubTopicMappingType].includes(mappingType)
}

const createMappingFilter = (mappingType, cohortID) => {
  let filters
  if (mappingType === SubjectMappingType) {
    filters = { cohort_id: cohortID, type: 'subject' }
  } else if (mappingType === ChapterMappingType) {
    filters = { cohort_id: cohortID, type: 'chapter' }
  }
  return filters
}

const updateChoices = (choices) => {
  let cohortIDs = choices.map((choice) => choice.tnl_cohort_id);
  let refetchData = false;
  for (let id of cohortIDs) {
    if (!cohortChoices.hasOwnProperty(id)) {
      refetchData = true;
      break;
    }
  }
  let promise = new Promise((resolve, reject) => {
    if (refetchData) {
      TLLMSDataProvider.getMany('cohorts', {ids: cohortIDs}).then(
        data => {
          updateChoicesObject(data);
          resolve();
        }
      );
    } else {
      reject();
    }
  });
  return promise;
}

const updateChoicesObject = (data) => {
  for (let choice of data.data) {
    cohortChoices[choice.id] = choice.name;
  }
  isLoading = false;
}

export default VideoElementMappingCreate;
