import * as React from 'react';
import {
  TextField, Show, TabbedShowLayout, Tab, NumberField, ReferenceField
} from 'react-admin';

import useDefaultStyles from "../../../common_components/Themes"

export const VideoElementPropertiesShow = () => {
  const classes = useDefaultStyles();
  return (
    <Show actions={false}>
      <TabbedShowLayout className={classes.secondaryColor}>
        <Tab label='Element Properties Details'>
          <NumberField source='id' />
          <ReferenceField source="category_id" reference="video/categories" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="subject_id" label="Subject ID" />
          <ReferenceField source="subject_id" reference="categories" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="chapter_id" label="Chapter ID" />
          <ReferenceField source="chapter_id" reference="categories" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="subtopic_id" label="Subtopic ID" />
          <ReferenceField source="subtopic_id" reference="categories" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="concept_id" label="Concept ID" />
          <ReferenceField source="concept_id" reference="concepts" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="tags" />
          <TextField source="metadata_presenter" />
          <TextField source="metadata_video_type" />
          <TextField source="metadata_audio" />
          <TextField source="metadata_thumbnail" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default VideoElementPropertiesShow;