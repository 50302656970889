import * as React from "react";
import { useRecordContext } from 'react-admin';
import {
  List, Datagrid, TextField, TopToolbar, ExportButton, Button,
  useRedirect, EditButton, ReferenceField
} from 'react-admin';

import Empty from 'common_components/Empty';
import { hasWriteAccess } from 'common_validations/roles';


const ElementPropertiesTab = ({ source }) => {
  const record = useRecordContext();
  return (
      <List
        resource='video/element-properties'
        filter={{ video_element_id: record.id }}
        actions={<VideoElementPropertiesActions />}
        empty={
          <Empty
            canWrite={hasWriteAccess('video_widgets')}
            customCreateButton={<PropertiesCreateButton variant='container' />}
          />
        }
      >
        <Datagrid rowClick='show' bulkActionButtons={false}>
          <TextField source="id" />
          <ReferenceField source="category_id" reference="video/categories" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="subject_id" label="Subject ID" />
          <TextField source="chapter_id" label="Chapter ID" />
          <TextField source="subtopic_id" label="Subtopic ID" />
          <TextField source="concept_id" label="Concept ID" />
          <TextField source="tags" />
          {hasWriteAccess('video_widgets') && <EditButton />}
        </Datagrid>
      </List>
  )
}

const PropertiesCreateButton = () => {
  const context = useRecordContext();
  const redirect = useRedirect();
  const handleClick = () => {
    redirect('create', 'video/element-properties', 0, {}, {
        "record": {
          "video_element_id": context.id,
          "video_widget_id": context.video_widget_id
        }
      });
  }
 return (
   <Button label='Create' color='primary' onClick={handleClick} />
 )
}

const VideoElementPropertiesActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
)

export default ElementPropertiesTab;
