import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import AodIcon from '@mui/icons-material/Aod';
import VideoFileIcon from '@mui/icons-material/VideoFile';

import { hasReadAccess } from 'common_validations/roles';

export const MenuBar = (props) => (
    <Menu {...props}>
        {
          hasReadAccess('sdui') &&
          <MenuItemLink to="/sdui" primaryText="SDUI Component" leftIcon={<AodIcon />}/>
        }
        {
          hasReadAccess('video_widgets') &&
          <MenuItemLink to="/widget/video/widgets" primaryText="Video Widgets" leftIcon={<VideoFileIcon />}/>
        }
    </Menu>
);

export default MenuBar;
