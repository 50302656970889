import * as React from "react";
import { Admin, Resource, Layout } from 'react-admin';

import dataProvider from './data_provider';
import MenuBar from 'common_components/menu_bar';
import CustomAppBar from 'common_components/app_bar';
import CustomTheme from 'Themes/CustomTheme'

import { hasReadAccess, hasWriteAccess } from 'common_validations/roles';

import UIComponentList from './ui_component/list';
import UIComponentShow from './ui_component/show';
import UIComponentEdit from './ui_component/edit';
import UIComponentCreate from './ui_component/create';

const CustomLayout = (props) => <Layout
    {...props}
    menu={MenuBar}
    appBar={CustomAppBar}
/>;

const UIComponentAdmin = () => (
    <Admin theme={CustomTheme} key='sdui' layout={CustomLayout} basename="/sdui" dataProvider={dataProvider}>
      <Resource
        name="admin"
        list={hasReadAccess('sdui') && UIComponentList}
        show={hasReadAccess('sdui') && UIComponentShow}
        edit={hasWriteAccess('sdui') && UIComponentEdit}
        create={hasWriteAccess('sdui') && UIComponentCreate}
        options={{ label: 'SDUI Components' }}
      />
    </Admin>
);

export default UIComponentAdmin;
