import { stringify } from 'query-string';
import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';

const updateDataProvider = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
  return fetchUtils.fetchJson(url, options);
}

const tllmsBaseURL = process.env.REACT_APP_TLLMS_BASE_URL + '/web/v1/cms'

const baseTllmsDataProvider = jsonServerProvider(tllmsBaseURL, updateDataProvider);
const TLLMSDataProvider = {
  ...baseTllmsDataProvider,
  getMany: (resource, params) => {
    const query = {
      'id[]': params.ids,
    };
    const url = `${tllmsBaseURL}/${resource}?${stringify(query)}`;
    return updateDataProvider(url).then(({ json }) => ({ data: json }));
  },
  getRoles: (token) => {
    const url = `${process.env.REACT_APP_TLLMS_BASE_URL}/web/v1/me`
    return updateDataProvider(url)
  }
}

export default TLLMSDataProvider
