const OAuthLocalConfig = {
  clientId: process.env.REACT_APP_TLLMS_CLIET_ID,
  redirectUri: process.env.REACT_APP_BASE_URL + '/authenticate',
};
const LocalConfig = {
  OAuth: OAuthLocalConfig,
  oauthURL: process.env.REACT_APP_TLLMS_BASE_URL + `/oauth/authorize?client_id=${
    OAuthLocalConfig.clientId
  }&redirect_uri=${encodeURIComponent(
    OAuthLocalConfig.redirectUri
  )}&response_type=token&scope=profile&auth_type=email`,
};

export default Object.assign(
  LocalConfig
);
