import React from 'react';
import { Button } from '@mui/material';
import { InputLabel } from '@mui/material';
import _ from 'lodash';
import { withStyles } from '@mui/styles';
 import compose from 'recompose/compose';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import Progress from './Loading';
import Styles from './Styles';
import Config from '../../Config/AppConfig';

const styleSheet = (theme) => Styles;

class LoginPage extends React.Component {
  componentWillMount() {
    let that = this;
    (function (d, s, id) {
      let js;
      let bjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = `${Config.loginURl}/assets/connect/sdk.js`;
      bjs.parentNode.insertBefore(js, bjs);
    })(document, 'script', 'tnl-jssdk');
    window.bjsAsyncInit = function () {
      if (!that.props.inited) {
      }
    };
    this.redirectToParticularScreen(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.redirectToParticularScreen(nextProps);
  }

  redirectToParticularScreen(props) {
  }

  render() {
    const theme = createTheme();
    const { classes, fetching } = this.props;
    let error = _.get(this.props, 'session.error.error') || {};
    return (
      <div className={classes.rootContainer}>
        {fetching ? (
          <Progress center size={80} />
        ) : (
          <ThemeProvider theme={theme}>
            <div className={classes.LoginContainer}>
              <div className={classes.imageContainer}>
                <img
                  width={151}
                  src={'/Images/logo.svg'}
                  alt="logo"
                  className={classes.skipIcon}
                />
              </div>
              <div>
                <img
                  src={'/Images/askADoubtEse.svg'}
                  alt="skaskADoubtEseip"
                  className={classes.skipIcon}
                />
              </div>
              <Button
                component="a"
                color="primary"
                href={Config.oauthURL}
                className={classes.button}
                classes={{ textPrimary: classes.raisedBtn }}
              >
                LOGIN
              </Button>
              {error.code === 401 && (
                <div>
                  <InputLabel error> {error.message} </InputLabel>
                </div>
              )}
            </div>
          </ThemeProvider>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styleSheet)
)(LoginPage);
