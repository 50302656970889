import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleClickLogout = () => {
    localStorage.removeItem('token')
    navigate("/login");
  }
  return (
    <Button
      color='inherit'
      variant='outlined'
      onClick={handleClickLogout}
    >
      Logout
    </Button>
  )
}

export default LogoutButton;
