import * as React from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  SimpleForm,
  TextInput,
  required, SelectInput,
} from 'react-admin';
import {Cohorts} from './components/Cohorts'
import {  ElementPropertiesMetadataPresenter, ElementPropertiesMetadataThumbnail, ElementPropertiesMetadataAudio,ElementPropertiesMetadataVideoType} from "../../constants/choices";

const VideoElementPropertiesCreate = (props) => {
  const location = useLocation();
  const videoWidgetId = location.state && location.state.record ? location.state.record.video_widget_id : undefined;
  return (
    <Create title='Create a Post'>
      <SimpleForm>
        <TextInput source='video_element_id' validate={[required()]} disabled fullWidth />
        <Cohorts videoWidgetId={videoWidgetId} validate={[required()]} resettable />
        <TextInput source='tags' resettable fullWidth />
        <SelectInput source='metadata_presenter' choices={ElementPropertiesMetadataPresenter} resettable fullWidth />
        <SelectInput source='metadata_video_type' choices={ElementPropertiesMetadataVideoType} resettable fullWidth />
        <SelectInput source='metadata_audio' choices={ElementPropertiesMetadataAudio} resettable fullWidth />
        <SelectInput source='metadata_thumbnail' choices={ElementPropertiesMetadataThumbnail} resettable fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default VideoElementPropertiesCreate;