import * as React from 'react';
import {
  List, Datagrid, TextField, EditButton, TextInput, ExportButton, CreateButton, TopToolbar
} from 'react-admin';

import { hasWriteAccess } from 'common_validations/roles';
import IsAvailableField from 'common_components/IsAvailableField'
import ListChipField from 'common_components/ListChipField'

const VideoElementFilters = [
  <TextInput source='query' label='Search Name' alwaysOn />
]

const VideoElementActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <CreateButton />}
    <ExportButton />
  </TopToolbar>
)

export const VideoElementList = () => (
  <List actions={<VideoElementActions />} filters={VideoElementFilters}>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <TextField source='id' />
      <TextField source='name' />
      <ListChipField source='label_list' />
      <IsAvailableField label='Is Available' />
      <EditButton />
    </Datagrid>
  </List>
);

export default VideoElementList;
