import { Fragment, useState } from 'react';
import { TextInput, useGetList, useEditContext, AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Chapters } from "./Chapters";

const Subjects = (props) => {
  const { record } = useEditContext();
  const [subjectId, setSubjectId] = useState(record?.subject_id);

  const formContext = useFormContext();
  const handleSubjectIdChange = (value) => {
    setSubjectId(value);
    formContext.setValue('subject_name', "");
    formContext.setValue('chapter_id', "");
    formContext.setValue('chapter_name', "");
    formContext.setValue('subtopic_id', "");
    formContext.setValue('subtopic_name', "");
    formContext.setValue('concept_id', "");
    formContext.setValue('concept_name', "");
  };

  const { data: subjectsResponse , isLoading: areSubjectsLoading, error: subjectsError } = useGetList('categories', {
    filter: {
      type: 'subject',
      cohort_id: props.cohortId
    },
    pagination: { page: 1, perPage: 200 }
  });

  const SubjectNameInput = (props) => {
    const { data, isLoading, error } = useGetList('categories', {
      filter: {
        id: props.subjectId || ""
      }
    });

    if (isLoading || error || data.length === 0) return (<Fragment> </Fragment>)

    if (formContext.getValues('subject_id') !== "") {
      formContext.setValue('subject_name', data[0]?.name);
    }
    return (
        <TextInput source='subject_name' disabled fullWidth />
    );
  };

  if (areSubjectsLoading || subjectsError) return (<Fragment> </Fragment>)
  return (
    <Fragment>
      <AutocompleteInput source='subject_id' onChange={handleSubjectIdChange} choices={subjectsResponse} optionText="id" parse={value => value.toString()} resettable fullWidth />
      <SubjectNameInput subjectId={subjectId} />
      <Chapters subjectId={subjectId}/>
    </Fragment>
  )
}

export { Subjects };