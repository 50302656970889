import * as React from 'react';
import {
  Create, SimpleForm, TextInput, NumberInput, DateTimeInput, SelectInput, required, useCreate,
  useRedirect, FormDataConsumer
} from 'react-admin';

import { WidgetTypeChoices } from 'widget_service/constants/choices';
import { availabilityStartingValidations, availabilityUntilValidations } from './validations';
import { NAME_FIELD_DESCRIPTION } from 'widget_service/constants/field_descriptions';
import { CanDisplayField } from 'widget_service/video/utils';

export const VideoWidgetCreate = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const save = React.useCallback(
    async values => {
      var response;
      try {
        response = await create('video/widgets', { data: values }, { returnPromise: true });
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
      redirect('show', 'video/widgets', response['id']);
    },
    [create, redirect]
  );

  return (
    <Create {...props}>
      <SimpleForm onSubmit={save}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let widgetType = formData.type;

            return (
              <>
                <TextInput source='name' validate={[required()]} helperText={ NAME_FIELD_DESCRIPTION } fullWidth />
                <SelectInput source='type' choices={WidgetTypeChoices} validate={[required()]} fullWidth />
                {CanDisplayField(widgetType, 'widget_availability_starting') && <DateTimeInput
                  source='availability_starting'
                  validate={availabilityStartingValidations}
                  fullWidth
                />}
                {CanDisplayField(widgetType, 'widget_availability_until') && <DateTimeInput
                  source='availability_until'
                  validate={availabilityUntilValidations}
                  fullWidth
                />}
                {CanDisplayField(widgetType, 'widget_sort_sequence') && <NumberInput
                  source='sort_sequence'
                  validate={[required()]}
                  fullWidth
                />}
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default VideoWidgetCreate;
