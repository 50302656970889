export const AppFlavourChoices = [
  { id: '1', name: 'BTLA Learning App' },
  { id: '2', name: 'K5 App' },
  { id: '4', name: 'Web App' },
  { id: '5', name: 'K12 Parity App' },
  { id: '6', name: 'K5 Parity App' },
  { id: '7', name: 'K3 App' },
  { id: '8', name: 'Tutor Plus App' },
  { id: '11', name: 'BTLA Online Premium App' },
  { id: '12', name: 'Aakash App' },
  { id: '13', name: 'Aakash Parity App' },
];

export const DeviceOrientationChoices = [
  { id: 'portrait', name: 'Portrait (Mobile)' },
  { id: 'landscape', name: 'Landscape (Tablet)' },
];

export const DeviceOSChoices = [
  { id: 'android', name: 'Android' },
  { id: 'ios', name: 'IOS' },
];

export const ThemeNameChoices = [
  {
    id: 'lower_kg',
    name: 'Lower KG (LKG, UKG, Standard I, Standard II, Standard III)',
  },
  { id: 'lower', name: 'Lower (Standard IV, Standard V)' },
  { id: 'middle', name: 'Middle (Standard VI, Standard VII)' },
  {
    id: 'higher',
    name: 'Higher (Standard VIII, Standard IX, Standard X, Other)',
  },
  {
    id: 'higher_plus',
    name: 'Higher Plus (Standard XI, Standard XII, Standard XIII)',
  },
];

export const GradeChoices = [
  { id: 'standard_i', name: 'Standard I' },
  { id: 'standard_ii', name: 'Standard II' },
  { id: 'standard_iii', name: 'Standard III' },
  { id: 'standard_iv', name: 'Standard IV' },
  { id: 'standard_v', name: 'Standard V' },
  { id: 'standard_vi', name: 'Standard VI' },
  { id: 'standard_vii', name: 'Standard VII' },
  { id: 'standard_viii', name: 'Standard VIII' },
  { id: 'standard_ix', name: 'Standard IX' },
  { id: 'standard_x', name: 'Standard X' },
  { id: 'standard_xi', name: 'Standard XI' },
  { id: 'standard_xii', name: 'Standard XII' },
  { id: 'standard_xiii', name: 'Standard XIII' },
  { id: 'other', name: 'Other' },
];

export const SubscriptionTypeChoices = [
  { id: 'free', name: 'Free' },
  { id: 'paid_sync', name: 'Paid Sync' },
  { id: 'paid_async', name: 'Paid Async' },
  { id: 'neo', name: 'Neo' },
  { id: 'blc', name: 'Blc' },
];

export const SubjectChoices = [
  { id: 'maths', name: 'Mathematics' },
  { id: 'physics', name: 'Physics' },
  { id: 'chemistry', name: 'Chemistry' },
  { id: 'biology', name: 'Biology' },
];

export const ComponentTypeChoices = [
  { id: 'Action Bar', name: 'Action Bar' },
  { id: 'Bottom Bar', name: 'Bottom Bar' },
  { id: 'Home Layout', name: 'Home Layout' },
  { id: 'Theme Layout', name: 'Theme Layout' },
  { id: 'Btc Layout', name: 'Btc Layout' },
  { id: 'Self Study Layout', name: 'Self Study Layout' },
  { id: 'Home Space', name: 'Home Space' },
  { id: 'Assess Space', name: 'Assess Space' },
  { id: 'Learn Space', name: 'Learn Space' },
  { id: 'Topic Space', name: 'Topic Space' },
  { id: 'Aakash Action Bar', name: 'Aakash Action Bar' },
  { id: 'Aakash Bottom Bar', name: 'Aakash Bottom Bar' },
  { id: 'Aakash Theme Layout', name: 'Aakash Theme Layout' },
];

export const Types = {
  ACTION_BAR: 'Action Bar',
  BOTTOM_BAR: 'Bottom Bar',
  HOME_LAYOUT: 'Home Layout',
  BTC_LAYOUT: 'Btc Layout',
  SELF_STUDY_LAYOUT: 'Self Study Layout',
  THEME_LAYOUT: 'Theme Layout',
  HOME_SPACE: 'Home Space',
  ASSESS_SPACE: 'Assess Space',
  LEARN_SPACE: 'Learn Space',
  TOPIC_SPACE: 'Topic Space',
  AAKASH_ACTION_BAR: 'Aakash Action Bar',
  AAKASH_BOTTOM_BAR: 'Aakash Bottom Bar',
  AAKASH_THEME_LAYOUT: 'Aakash Theme Layout'
};
export const RequiredInputsByType = {
  [Types.ACTION_BAR]: [
    'app_flavour_id',
    'cohort_id',
    'schema_version',
    'device_os',
    'subscription_type',
    'schema',
  ],
  [Types.BOTTOM_BAR]: [
    'app_flavour_id',
    'cohort_id',
    'schema_version',
    'device_orientation',
    'device_os',
    'subscription_type',
    'schema',
  ],
  [Types.BTC_LAYOUT]: [
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'app_flavour_id',
    'theme_name',
    'schema',
  ],
  [Types.SELF_STUDY_LAYOUT]: [
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema_version',
    'theme_name',
    'schema',
  ],
  [Types.HOME_LAYOUT]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'theme_name',
    'schema',
  ],
  [Types.THEME_LAYOUT]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'theme_name',
    'schema',
  ],
  [Types.HOME_SPACE]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
  [Types.ASSESS_SPACE]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
  [Types.LEARN_SPACE]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
  [Types.TOPIC_SPACE]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
  [Types.AAKASH_ACTION_BAR]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
  [Types.AAKASH_BOTTOM_BAR]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
  [Types.AAKASH_THEME_LAYOUT]: [
    'schema_version',
    'app_flavour_id',
    'cohort_id',
    'device_orientation',
    'device_os',
    'schema',
    'subject',
    'subscription_type'
  ],
};
