import * as React from "react";
import {
  List, Datagrid, TextField, EditButton, TextInput, ExportButton, CreateButton, TopToolbar
} from 'react-admin';

import { hasWriteAccess } from 'common_validations/roles';

const VideoElementMappingFilters = [
  <TextInput source="query" label="Search Name" alwaysOn />
]

const VideoElementMappingActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <CreateButton />}
    <ExportButton />
  </TopToolbar>
)

export const VideoElementMappingList = () => (
  <List actions={<VideoElementMappingActions />} filters={VideoElementMappingFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="display_name" />
      <TextField source="mapping_type" />
      <TextField source="mapping_id" />
      {hasWriteAccess('video_widgets') && <EditButton />}
    </Datagrid>
  </List>
);

export default VideoElementMappingList;
