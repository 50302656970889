import { required } from 'react-admin';

const afterStartingTime = (value, allValues) => {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  if (allValues.availability_starting > value) {
    return 'Availability Until cannot be before Availability Starting'
  }
}

const validTimePeriod = (value) => {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  let now = new Date();
  let nextDay = 24 * 60 * 60 * 1000;
  let diffInMs = value - now;
  if (diffInMs < 0) {
    return 'Date cannot be of past.'
  } else if (diffInMs > nextDay) {
    return 'Cannot schedule later than 24hrs from now'
  }
  return undefined
}

const validateUniqueSubjects = (value) => {
  return null
}

export const availabilityStartingValidations = [required(), validTimePeriod]
export const availabilityUntilValidations = [required(), afterStartingTime]
export const widgetMappingValidations = [validateUniqueSubjects]
