import * as React from "react";
import {
  List, Datagrid, TextField, EditButton, TextInput, ExportButton, CreateButton, TopToolbar
} from 'react-admin';

import { hasWriteAccess } from 'common_validations/roles';
import IsAvailableField from 'common_components/IsAvailableField'
import ListChipField from 'common_components/ListChipField'

const VideoWidgetMappingFilters = [
  <TextInput source="query" label="Search Name" alwaysOn />
]

const VideoWidgetMappingActions = () => (
  <TopToolbar>
    {hasWriteAccess('video_widgets') && <CreateButton />}
    <ExportButton />
  </TopToolbar>
)

export const VideoWidgetMappingList = () => (
  <List actions={<VideoWidgetMappingActions />} filters={VideoWidgetMappingFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <ListChipField source="label_list" />
      <IsAvailableField label="Is Available" />
      {hasWriteAccess('video_widgets') && <EditButton />}
    </Datagrid>
  </List>
);

export default VideoWidgetMappingList;
