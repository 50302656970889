import React from 'react';
import { useRecordContext, TextInput, required } from 'react-admin';

const jsonValidation = (value) => {
  return () => {
    try {
      if (value !== '{}') {
        JSON.parse(value);
      } else {
        return 'Empty JSON not accepted';
      }
    } catch (e) {
      return 'Incorrect JSON format';
    }
  };
};

const JSONInput = ({ source, formData, ...props }) => {
  const record = useRecordContext();
  let objectConstructor = {}.constructor;

  if (
    record !== undefined &&
    ![null, undefined].includes(record[source]) &&
    record[source].constructor === objectConstructor
  ) {
    record[source] = JSON.stringify(record[source]);
  }

  return (
    <TextInput
      source={source}
      multiline={true}
      {...props}
      validate={[required(), jsonValidation(formData[source])]}
      fullWidth
    />
  );
};

export default JSONInput;
