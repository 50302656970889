import * as React from 'react';
import {
  Edit, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput, useUpdate, useRedirect,
  useChoicesContext
} from 'react-admin';

import NoDeleteToolbar from 'common_components/NoDeleteToolbar';
import { PageTypeChoices } from 'widget_service/constants/choices';
import { CanDisplayField } from 'widget_service/video/utils';

const WidgetMapping = (props) => {
  const { selectedChoices } = useChoicesContext();
  let widgetType;
  if (selectedChoices.length !== 0 && selectedChoices[0] !== undefined) {
    widgetType = selectedChoices[0].type
  }
  return (
    <>
      <AutocompleteInput optionText="name" validate={[required()]} fullWidth />
      <ReferenceInput label="Tnl Cohort" source="tnl_cohort_id" reference="cohorts">
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
      {CanDisplayField(widgetType, 'page_filter') && <AutocompleteInput
        source="page_type"
        choices={PageTypeChoices}
        fullWidth
      />}
      {CanDisplayField(widgetType, 'user_segment_filter') && <TextInput
        source="user_segment" fullWidth
      />}
    </>
  )
}

export const VideoWidgetMappingEdit = (props) => {
  const [update] = useUpdate();
  const redirect = useRedirect();
  const save = React.useCallback(
      async values => {
        try {
          await update('video/widget-mappings', { id: values['id'], data: values }, { returnPromise: true });
        } catch (error) {
          if (error.body.errors) {
            return error.body.errors;
          }
        }
        redirect('show', 'video/widget-mappings', values['id']);
      },
      [update, redirect]
  );

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<NoDeleteToolbar />} onSubmit={save}>
        <ReferenceInput label="Video Widget" source="video_widget_id" reference="video/widgets">
          <WidgetMapping />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default VideoWidgetMappingEdit;
