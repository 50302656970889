import * as React from 'react';
import { useRecordContext } from 'react-admin';
import {
  Show, TextField, TabbedShowLayout, Tab, DateField, SelectField, NumberField, Button,
  TopToolbar, EditButton, useDataProvider, useRefresh, useNotify
} from 'react-admin';
import { useMutation } from 'react-query';
import { WidgetTypeChoices, StatusChoices } from 'widget_service/constants/choices';
import useDefaultStyles from "../../../common_components/Themes"
import { hasWriteAccess } from 'common_validations/roles';

import ElementsTab from './elements_tab'
import WidgetMappingsTab from './mappings_tab';

export const VideoWidgetShow = (props) => {
  const classes = useDefaultStyles();
  return (
    <Show actions={<VideoWidgetActions />}>
      <TabbedShowLayout className={classes.secondaryColor}>
        <Tab label='Widget Details'>
          <TextField source='id' />
          <TextField source='name' fullWidth />
          <SelectField source='type' choices={WidgetTypeChoices} disabled fullWidth />
          <DateField source='availability_starting' showTime fullWidth />
          <DateField source='availability_until' showTime fullWidth />
          <NumberField source='sort_sequence' fullWidth />
          <SelectField source='status' choices={StatusChoices.choices} />
        </Tab>
        <Tab label='Video Elements'>
          <ElementsTab />
        </Tab>
        <Tab label='Widget Mappings'>
          <WidgetMappingsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const ToggleStatusButton = ({ widgetID, displayText }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { mutate } = useMutation(
    () => dataProvider.toggleStatus('video/widgets', {widgetID: widgetID}).then(
      (data) => {
        refresh();
      },
      error => {
        if (error?.body?.errors?.non_field_errors) {
          notify(error.body.errors.non_field_errors, { type: 'error' })
        }
      }
    )
  );
  return <Button label={displayText} onClick={() => mutate()} />;
};

const VideoWidgetActions = () => {
  let context = useRecordContext();
  let buttonText = (context !== undefined && context.status === 'draft') ? 'Publish' : 'Draft';

  return (
    <TopToolbar>
      {
        hasWriteAccess('video_widgets') && <>
          <ToggleStatusButton widgetID={context?.id} displayText={buttonText} />
          <EditButton />
        </>
      }
    </TopToolbar>
  )
}

export default VideoWidgetShow;
