const styles = {
    LoginContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '2em',
      borderRadius: '0.25em',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0.25em 0 rgba(0, 0, 0, 0.1)',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    button: {
      margin: '0.5em',
      width: '100%',
    },
    raisedBtn: {
      boxShadow:
        '0 0.125em 0.125em 0 rgba(0, 0, 0, 0.24), 0 0 0.125em 0 rgba(0, 0, 0, 0.12)',
      borderRadius: '0.125em',
      backgroundColor: '#792877',
      color: '#FFFFFF',
      fontFamily: 'Gotham-Medium',
      fontSize: '0.875em',
      fontWeight: 500,
      letterSpacing: 0.5,
      '&:hover': {
        backgroundColor: '#792877',
        color: '#FFFFFF',
      },
      '&:focus': {
        backgroundColor: '#transperant',
        color: '#FFFFFF',
      },
    },
    rootContainer: {
      backgroundColor: '#F2F2F2',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageContainer: {
      paddingBottom: '3.125em',
      alignItems: 'flex-start',
      textAlign: 'left',
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
    },
  };
  
 export default styles;
