import * as React from "react";
import { useRecordContext } from 'react-admin';
import {
  List, Datagrid, TextField, SelectField, TopToolbar, ExportButton, ReferenceField
} from 'react-admin';

import { PageTypeChoices } from 'widget_service/constants/choices';

const VideoWidgetMappingActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
)

const WidgetMappingsTab = ({ source }) => {
  const record = useRecordContext();
  return (
      <List
        resource='video/widget-mappings'
        filter={{ video_widget_id: record.id }}
        actions={<VideoWidgetMappingActions />}
      >
        <Datagrid rowClick='show' bulkActionButtons={false}>
          <TextField source='id' />
          <ReferenceField label='Cohort' reference='cohorts' source='tnl_cohort_id' link={false}>
              <TextField source='name' />
          </ReferenceField>
          <SelectField source='page_type' choices={PageTypeChoices} />
          <TextField source='user_segment' />
        </Datagrid>
      </List>
  )
}

export default WidgetMappingsTab;

