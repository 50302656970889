import * as React from 'react';
import {
  SimpleForm,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  Create,
  useCreate,
  useRedirect,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import {
  AppFlavourChoices,
  DeviceOrientationChoices,
  DeviceOSChoices,
  ThemeNameChoices,
  GradeChoices,
  SubscriptionTypeChoices,
  SubjectChoices,
  ComponentTypeChoices,
} from './constants';
import JSONInput from 'common_components/JSONInput';
import { is_field_editable } from 'common_validations/sdui_form_validations';

export const UIComponentCreate = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const save = React.useCallback(
    async (values) => {
      values['schema'] = values['schema'] ? JSON.parse(values['schema']) : {};

      var response;
      try {
        response = await create(
          'admin',
          { data: values },
          { returnPromise: true }
        );
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
      redirect('show', 'admin', response['id']);
    },
    [create, redirect]
  );

  return (
    <Create {...props}>
      <SimpleForm onSubmit={save}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const type = formData.type;

            return (
              <>
                <AutocompleteInput
                  choices={ComponentTypeChoices}
                  source="type"
                  fullWidth
                />

                <AutocompleteArrayInput
                  source="app_flavour_id"
                  choices={AppFlavourChoices}
                  disabled={!is_field_editable(type, 'app_flavour_id')}
                  isRequired={is_field_editable(type, 'app_flavour_id')}
                  fullWidth
                />
                <NumberInput
                  source="schema_version"
                  fullWidth
                  disabled={!is_field_editable(type, 'schema_version')}
                  isRequired={is_field_editable(type, 'schema_version')}
                />
                <ReferenceArrayInput
                  reference="cohorts"
                  source="cohort_id"
                >
                  <AutocompleteArrayInput
                    disabled={!is_field_editable(type, "cohort_id")}
                    isRequired={is_field_editable(type, "cohort_id")}
                    optionText="name"
                    format={value => value && value.map(id => parseInt(id))}
                    parse={value => value.map(id => String(id))}
                    fullWidth
                  />
                </ReferenceArrayInput>
                <AutocompleteArrayInput
                  source="device_orientation"
                  choices={DeviceOrientationChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'device_orientation')}
                  isRequired={is_field_editable(type, 'device_orientation')}
                />
                <AutocompleteArrayInput
                  source="device_os"
                  choices={DeviceOSChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'device_os')}
                  isRequired={is_field_editable(type, 'device_os')}
                />
                <AutocompleteArrayInput
                  source="theme_name"
                  choices={ThemeNameChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'theme_name')}
                  isRequired={is_field_editable(type, 'theme_name')}
                />
                <AutocompleteArrayInput
                  source="grade"
                  choices={GradeChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'grade')}
                  isRequired={is_field_editable(type, 'grade')}
                />
                <AutocompleteArrayInput
                  source="subscription_type"
                  choices={SubscriptionTypeChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'subscription_type')}
                  isRequired={is_field_editable(type, 'subscription_type')}
                />
                <AutocompleteArrayInput
                  source="subject"
                  choices={SubjectChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'subject')}
                  isRequired={is_field_editable(type, 'subject')}
                />
                <JSONInput
                  source="schema"
                  disabled={!is_field_editable(type, 'schema')}
                  isRequired={is_field_editable(type, 'schema')}
                  formData={formData}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default UIComponentCreate;
