import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  useUpdate,
  useRedirect,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import NoDeleteToolbar from 'common_components/NoDeleteToolbar';
import JSONInput from 'common_components/JSONInput';
import JSONCompareButton from 'common_components/json_compare';

import {
  AppFlavourChoices,
  DeviceOrientationChoices,
  DeviceOSChoices,
  ThemeNameChoices,
  GradeChoices,
  SubscriptionTypeChoices,
  SubjectChoices,
} from './constants';
import { is_field_editable } from 'common_validations/sdui_form_validations';

export const UIComponentEdit = (props) => {
  const [update] = useUpdate();
  const redirect = useRedirect();
  const save = React.useCallback(
    async (values) => {
      values['schema'] = values['schema'] ? JSON.parse(values['schema']) : {};

      try {
        await update(
          'admin',
          { id: values['id'], data: values },
          { returnPromise: true }
        );
      } catch (error) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
      redirect('show', 'admin', values['id']);
    },
    [update, redirect]
  );
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<NoDeleteToolbar />} onSubmit={save}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const type = formData.type;
            return (
              <>
                <TextInput disabled source="id" />
                <TextInput source="type" fullWidth disabled />
                <AutocompleteArrayInput
                  source="app_flavour_id"
                  choices={AppFlavourChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'app_flavour_id')}
                  isRequired={is_field_editable(type, 'app_flavour_id')}
                />
                <NumberInput
                  source="schema_version"
                  fullWidth
                  disabled={!is_field_editable(type, 'schema_version')}
                  isRequired={is_field_editable(type, 'schema_version')}
                />
                <ReferenceArrayInput
                  reference="cohorts"
                  source="cohort_id"
                >
                  <AutocompleteArrayInput
                    optionText='name'
                    format={value => value.map(id => parseInt(id))}
                    parse={value => value.map(id => String(id))}
                    disabled={!is_field_editable(type, 'cohort_id')}
                    isRequired={is_field_editable(type, 'cohort_id')}
                    fullWidth
                  />
                </ReferenceArrayInput>
                <AutocompleteArrayInput
                  source="device_orientation"
                  choices={DeviceOrientationChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'device_orientation')}
                  isRequired={is_field_editable(type, 'device_orientation')}
                />
                <AutocompleteArrayInput
                  source="device_os"
                  choices={DeviceOSChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'device_os')}
                  isRequired={is_field_editable(type, 'device_os')}
                />
                <AutocompleteArrayInput
                  source="theme_name"
                  choices={ThemeNameChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'theme_name')}
                  isRequired={is_field_editable(type, 'theme_name')}
                />
                <AutocompleteArrayInput
                  source="grade"
                  choices={GradeChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'grade')}
                  isRequired={is_field_editable(type, 'grade')}
                />
                <AutocompleteArrayInput
                  source="subscription_type"
                  choices={SubscriptionTypeChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'subscription_type')}
                  isRequired={is_field_editable(type, 'subscription_type')}
                />
                <AutocompleteArrayInput
                  source="subject"
                  choices={SubjectChoices}
                  fullWidth
                  disabled={!is_field_editable(type, 'subject')}
                  isRequired={is_field_editable(type, 'subject')}
                />
                <JSONInput
                  source="schema"
                  disabled={!is_field_editable(type, 'schema')}
                  isRequired={is_field_editable(type, 'schema')}
                  formData={formData}
                />
                <JSONCompareButton source="schema" />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default UIComponentEdit;
