import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import ReactJsonViewCompare from 'react-json-view-compare';
import { useRecordContext, FormDataConsumer } from 'react-admin';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

 
export default function JSONCompareButton({source}) {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = (formData) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
 
let newData;
let oldData;
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        try{
          newData = JSON.parse(formData[source])
        }catch{
          newData = {}  
        }

        try{
          oldData = JSON.parse(record[source])
        }catch{
          oldData = {}
        }
         return (
        <>
          <Button variant="outlined" onClick={() => handleClickOpen(formData)}>Compare schema with last one</Button>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              Schema Comparison
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <ReactJsonViewCompare newData={ newData } oldData={ oldData } />
            </DialogContent>
          </BootstrapDialog>
        </>
         )
      }
    }
    </FormDataConsumer>
  );
    
}

