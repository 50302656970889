const ALL_ACCESS = "All"

const READ_ACCESS_ROLES = {
  "sdui": [ALL_ACCESS],
  "video_widgets": [ALL_ACCESS]
}
const WRITE_ACCESS_ROLES = {
  "sdui": ["admin", "content_manager"],
  "video_widgets": ["admin", "content_manager"]
}

export const hasReadAccess = (component) => {
  return component in READ_ACCESS_ROLES && hasAccess(READ_ACCESS_ROLES[component])
};

export const hasWriteAccess = (component) => {
  return component in WRITE_ACCESS_ROLES && hasAccess(WRITE_ACCESS_ROLES[component])
};

const hasAccess = (roles) => {
  if (roles[0] === ALL_ACCESS) {
    return true
  }
  const userRoles = (localStorage.getItem("user_roles") || '').split(',');
  for (let r of userRoles) {
    if (roles.includes(r)) {
      return true
    }
  }
  return false
}
