import * as React from 'react';
import {
  TextField, SelectField, Show, TabbedShowLayout, Tab, NumberField
} from 'react-admin';

import { ElementMappingTypeChoices } from 'widget_service/constants/choices';
import ElementsTab from './elements_tab'

export const VideoElementMappingShow = () => {
  return (
    <Show actions={false}>
      <TabbedShowLayout>
        <Tab label='Element Mapping Details'>
            <NumberField source='id' />
            <SelectField source='mapping_type' choices={ElementMappingTypeChoices} fullWidth />
            <TextField source='display_name' fullWidth />
            <TextField source='mapping_id' fullWidth />
        </Tab>
        <Tab label='Elements'>
          <ElementsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default VideoElementMappingShow;

