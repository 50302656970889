import { fetchUtils, combineDataProviders } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import TLLMSDataProvider from 'common_components/data_providers';

const updateDataProvider = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('authorization', 'Bearer ' + localStorage.getItem('token'));
  return fetchUtils.fetchJson(url, options);
}

const widgetBaseURL = process.env.REACT_APP_WIDGET_SERVICE_BASE_URL;
const baseWidgetDataProvider = jsonServerProvider(widgetBaseURL, updateDataProvider);
const widgetDataProvider = {
  ...baseWidgetDataProvider,
  toggleStatus: (resource, params) => {
    const url = `${widgetBaseURL}/${resource}/${params.widgetID}/toggle-status`;
    return updateDataProvider(url, {method: 'PUT'}).then(({ json }) => ({ data: json }));
  },
  fetchCohort: (resource, params) => {
    const url = `${widgetBaseURL}/${resource}/${params.widgetID}/cohort`;
    return updateDataProvider(url, {method: 'GET'}).then(({ json }) => ({ data: json }));
  }
}

const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'cohorts':
        case 'categories':
        case 'concepts':
            return TLLMSDataProvider;
        default:
            return widgetDataProvider;
    }
});

export default dataProvider;
