import { Fragment, useState } from 'react';
import { useGetList, TextInput, useEditContext, AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const Subtopics = (props) => {
  const { record } = useEditContext();
  const [subtopicId, setSubtopicId] = useState(record?.subtopic_id);

  const formContext = useFormContext();
  const handleSubtopicIdChange = (value) => {
    setSubtopicId(value);
    formContext.setValue('subtopic_name', "");
  };

  const { data: subtopicsResponse , isLoading: areSubtopicsLoading, error: subtopicsError } = useGetList('categories', {
    filter: {
      type: 'subtopic',
      ancestry_id: props.chapterId || ""
    },
    pagination: { page: 1, perPage: 200 }
  });

  const SubtopicNameInput = (props) => {
    const { data, isLoading, error } = useGetList('categories', {
      filter: {
        id: props.subtopicId || ""
      }
    });

    if (isLoading || error || data.length === 0) return (<Fragment> </Fragment>)

    if (formContext.getValues('subtopic_id') !== "") {
      formContext.setValue('subtopic_name', data[0]?.name);
    }
    return (
        <TextInput source='subtopic_name' disabled fullWidth />
    );
  };

  if (areSubtopicsLoading || subtopicsError) return (<Fragment> </Fragment>)
  return  (
    <Fragment>
      <AutocompleteInput source='subtopic_id' onChange={handleSubtopicIdChange} choices={subtopicsResponse} optionText="id" parse={value => value.toString()} resettable fullWidth />
      <SubtopicNameInput subtopicId={subtopicId} />
    </Fragment>
  )
}

export { Subtopics };