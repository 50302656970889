import * as React from 'react';
import {
  Edit, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput, useUpdate, useRedirect
} from 'react-admin';

import NoDeleteToolbar from 'common_components/NoDeleteToolbar';
import { ElementMappingTypeChoices } from 'widget_service/constants/choices';

export const VideoElementMappingEdit = (props) => {
  const [update] = useUpdate();
  const redirect = useRedirect();
  const save = React.useCallback(
      async values => {
        try {
          await update('video/element-mappings', { id: values['id'], data: values }, { returnPromise: true });
        } catch (error) {
          if (error.body.errors) {
            return error.body.errors;
          }
        }
        redirect('show', 'video/element-mappings', values['id']);
      },
      [update, redirect]
  );

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<NoDeleteToolbar />} onSubmit={save}>
        <TextInput disabled source='id' />
        <AutocompleteInput source='mapping_type' validate={[required()]} choices={ElementMappingTypeChoices} fullWidth />
        <TextInput source='display_name' validate={[required()]} fullWidth />
        <TextInput source='mapping_id' validate={[required()]} fullWidth />
        <ReferenceInput label='Video Widget Mapping' source='video_widget_mapping_id' reference='video/widget-mappings'>
          <AutocompleteInput optionText='tnl_cohort_id' validate={[required()]} fullWidth />
        </ReferenceInput>
        <ReferenceInput label='Video Element' source='video_element_id' reference='video/elements'>
          <AutocompleteInput optionText='name' validate={[required()]} fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default VideoElementMappingEdit;
