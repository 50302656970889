import * as React from 'react';
import {
  TextField, Show, TabbedShowLayout, SingleFieldList, ChipField, Tab, ReferenceArrayField, TopToolbar,
  EditButton, CloneButton
} from 'react-admin';
import { AppFlavourChoices } from './constants';
import ListChipField from 'common_components/ListChipField';
import JSONField from 'common_components/JSONField';

import { hasWriteAccess } from 'common_validations/roles';

const SDUITopToolbar = (props) => (
  <TopToolbar>
    {
      hasWriteAccess('sdui') &&
      <>
        <CloneButton />
        <EditButton />
      </>
    }
  </TopToolbar>
)

export const UIComponentShow = () => {
  return (
    <Show actions={<SDUITopToolbar />}>
      <TabbedShowLayout>
        <Tab label="UI Component Details">
          <TextField source="id" />
          <TextField source="type" />
          <ListChipField source="app_flavour_id" choices={AppFlavourChoices} labelMap="app_flavours" />
          <TextField source="schema_version" />
          <ReferenceArrayField label='Cohorts' reference='cohorts' source='cohort_id'>
            <SingleFieldList linkType={false}>
              <ChipField source='name' />
            </SingleFieldList>
          </ReferenceArrayField>
          <ListChipField source="device_orientation" labelMap="orientations" />
          <ListChipField source="device_os" labelMap="os_list" />
          <ListChipField source="theme_name" labelMap="themes" />
          <ListChipField source="grade" labelMap="grades" />
          <ListChipField
            source="subscription_type"
            labelMap="subscription_types"
            emptyText="Empty"
          />
          <ListChipField source="subject" labelMap="subjects" />
          <JSONField source="schema" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UIComponentShow;
